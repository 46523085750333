
import {
  defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import Loader from '@/components/ui/Loader.vue';
import { useMultileveltestPlayer } from '@/domains/multileveltest/composables/useMultileveltestPlayer';
import { PlayerSessionStatus } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'AssignmentMultileveltestGateway',

  components: {
    Loader,
  },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props, context) {
    const { playerSessionId } = toRefs(props);

    const router = useRouter();

    const {
      content, fetchSession,
    } = useMultileveltestPlayer({ playerSessionId }, context);

    onMounted(async () => {
      await fetchSession();

      if (!content.value?.playerSessionStatus) return;

      // eslint-disable-next-line default-case
      switch (content.value?.playerSessionStatus) {
        case PlayerSessionStatus.NEW:
          router.replace({
            name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
            params: {
              atomType: AtomType.MULTILEVELTEST,
            },
          });
          break;
        case PlayerSessionStatus.ACTIVE:
          router.replace({
            name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO,
          });
          break;
        case PlayerSessionStatus.COMPLETED:
          router.replace({
            name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT,
          });
          break;
      }
    });
  },
});
